import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Image, Col, Row, Form } from "react-bootstrap";
import HwApiAmbassador from "../../ambassadors/HwApi";
import { useTranslation } from "react-i18next";
import i18n from "../../Middleware/i18n";
import i18next from "i18next";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectLanguage.css";

import ImgExit from "./../../Img/Close.png";

import { UserContext } from "./../../Main";

const ModalSelectLanguage = (props) => {
	const userStore = useContext(UserContext);

	const [language, setLanguage] = useState(i18n.language);

	//const [language, setLanguage] = useState(userStore.UserTranslation);
	const [showAbout, setShowAbout] = useState(false);

	const { t } = useTranslation("ui");

	const [data, setData] = useState([]);
	const history = useHistory();
	useEffect(() => {
		let response = require("./../../FakeData/servers.json");
		setData(response);
		// new HwApiAmbassador().getListServer((response) => {
		// 	setData(response.data);
		// });
		// // eslint-disable-next-line
	}, []);

	/**
	 * Send the new language to the user containd into the backoffice and
	 * the appContext set the language into the I18N
	 * @param {*} newLang language as Iso "fr-BE"
	 */
	function SetNewLanguage(newLang) {
		setLanguage(newLang);
		userStore.updateLanguage(newLang);
		i18next.changeLanguage(newLang);
		props.setShowSelectLanguage();
		history.push("/Home");
		//appContext.setLanguage(newLang);
	}

	return (
		<>
			<section className=''>
				<Modal
					size='xs'
					show={props.show}
					//onHide={props.setSelectServeurs(false)}
					centered
					scrollable
					backdrop='static'
					keyboard={false}>
					<Modal.Header>
						<Image className='ImgClose' src={ImgExit} onClick={() => props.setShowSelectLanguage()} style={{ cursor: "pointer", marginLeft: "455px" }} />
						<div className='TitleHeaderPosition'>
							<div className='TitleHeader'>Language</div>
						</div>
					</Modal.Header>
					<Modal.Body className='my-3 px-3 pt-3'>
						<Row
							className='d-flex listServer-item mx-5'
							id='fr-BE'
							name='fr-BE'
							action
							onClick={() => {
								SetNewLanguage("fr-BE");
							}}>
							<Col className='text-center'>French</Col>
						</Row>

						<Row
							className='d-flex listServer-item mx-5'
							id='en-GB'
							name='en-GB'
							checked={language === "en-GB"}
							onClick={() => {
								SetNewLanguage("en-GB");
							}}
							action>
							<Col className='text-center'>English</Col>
						</Row>

						{/* 
						<Form className='listServer-item'>
							<Form.Group>
								<div>
									<Form.Check
										inline
										id='nl-BE'
										name='nl-BE'
										type='radio'
										label={t("Dutch")}
										checked={language === "nl-BE"}
										onChange={() => {
											SetNewLanguage("nl-BE");
										}}
									/>
									<Form.Check
										inline
										id='en-GB'
										name='en-GB'
										type='radio'
										label={t("English")}
										checked={language === "en-GB"}
										onChange={() => {
											SetNewLanguage("en-GB");
										}}
									/>
									<Form.Check
										inline
										id='fr-BE'
										name='fr-BE'
										type='radio'
										label={t("French")}
										checked={language === "fr-BE"}
										onChange={() => {
											SetNewLanguage("fr-BE");
										}}
									/>
									<Form.Check
										inline
										id='de-DE'
										name='de-DE'
										type='radio'
										label={t("German")}
										checked={language === "de-DE"}
										onChange={() => {
											SetNewLanguage("de-DE");
										}}
									/>
								</div>
							</Form.Group>
						</Form> */}
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</section>
		</>
	);
};

export default ModalSelectLanguage;
