import React, { useState } from "react";

/**
 * context will be access with a hooks
 */
export const UserContext = React.createContext();

/**
 * Product context provider component used to provide the context throw the children components
 * @param {Array} props
 * @returns
 */
export const UserContextProvider = (props) => {
	const [CurrentServer, setCurrentServer] = useState("63");

	return (
		<UserContext.Provider
			value={{
				//state
				CurrentServer,
				//setState
				//service
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};
