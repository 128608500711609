import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Image, Container, Col, Row } from "react-bootstrap";

import BtnInfo from "./../Img/Component/BtnInfo.png";

import "./../css/Page.css";
import ModalSelectLanguage from "./Modal/SelectLanguage";

const PageFooter = () => {
	const history = useHistory();

	const [showModalSelectLanguage, setShowSelectLanguage] = useState(false);

	return (
		<>
			<Row className='PageBgFooter'>
				<Col className='float-start '>
					<span className='float-start text-color-footer'>Not Connected</span>
				</Col>

				<Col className='float-end'>
					{/* <span className='float-end text-color-footer'>Get Help</span>
					<span className='float-end text-separator'></span> */}
					<span className='float-end text-color-footer' onClick={() => history.push("/ContentPolicy")}>
						Legal Info
					</span>
					<span className='float-end text-separator'></span>
					<span className='float-end text-color-footer' onClick={() => setShowSelectLanguage(true)}>
						Languages
					</span>
				</Col>
			</Row>

			<ModalSelectLanguage show={showModalSelectLanguage} setShowSelectLanguage={setShowSelectLanguage} />
		</>
	);
};

export default PageFooter;
