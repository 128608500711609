import React, { useEffect, useState, useContext } from "react";
import { Image, Table } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";

import HwApiAmbassador from "./../../src/ambassadors/HwApi";

import PageFooter from "../components/PageFooter";
import ImgExit from "./../Img/Close.png";

import "./../css/Page.css";
import "./../css/Section.css";
import "./../css/Component.css";
import "./Home/Home.css";
import "./GwAdmin.css";

const GwAdmin = (props) => {
	const history = useHistory();

	const [Guilds, setGuilds] = useState([]);
	useEffect(() => {
		new HwApiAmbassador().getListGuildsServer("63", (response) => {
			setGuilds(response.data);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<section className='PgHome' style={{ height: "640px" }}>
				<div className='PageBgHeader'></div>
				<div className='PageTitleHeaderPosition d-flex'>
					<div className='PageTitleHeaderLeft'></div>
					<div className='PageTitleHeaderMain'>Admin Guilde </div>
					<div className='PageTitleHeaderRight'></div>
				</div>

				<Image
					className='float-end PgImgClose'
					src={ImgExit}
					style={{ cursor: "pointer" }}
					onClick={() => history.push("/Home")}
				/>

				<div className='col-sm-6 pt-2'>
					{Guilds.map(
						(item, i) =>
							item.leagues === "Silver" && (
								<>
									<div className='Guild-item'>{item.name}</div>
								</>
							),
					)}
				</div>
			</section>

			<PageFooter />
		</>
	);
};

export default GwAdmin;
