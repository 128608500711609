import React from "react";

import "./css/App.css";

//import Layout from "./Layout";

import Layout from "./Layout";

function App() {
	// const [locale, setLocale] = useState("en");

	return (
		<>
			<Layout />
		</>
	);
}

export default App;
