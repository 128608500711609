import React from "react";
import { Modal, Image } from "react-bootstrap";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectServer.css";

import ImgExit from "./../../Img/Close.png";

const ModalViewAdventuresMap = (props) => {
	return (
		<section>
			<Modal
				size='lg'
				show={props.show}
				//onHide={props.setSelectServeurs(false)}
				centered
				scrollable
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header className='mb-0 NoOpacity'>
					<div className='TitleHeaderPosition'>
						<div className='TitleHeader'>{props.AdventureName}</div>{" "}
					</div>
					<Image
						className='ImgClose'
						src={ImgExit}
						onClick={() => props.setShowModalViewMap()}
						style={{ cursor: "pointer" }}
					/>
				</Modal.Header>{" "}
				<Modal.Body
					className='m-0 p-0 text-center NoOpacity'
					style={{ overflow: "auto" }}
				>
					<Image
						style={{ maxHeight: "500px" }}
						className='map-img-fluid-noborder'
						src={"./../PublicImg/Adventures/" + props.AdventureID + ".jpg"}
					/>{" "}
				</Modal.Body>{" "}
				<Modal.Footer></Modal.Footer>
			</Modal>
		</section>
	);
};

export default ModalViewAdventuresMap;
