import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import Cookies from "universal-cookie";

import { resources } from "./../Locales/resources.js";

const cookies = new Cookies();

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		lng: cookies.get("language") || "fr-BE",
		fallbackLng: "fr-BE",
		resources: resources,
		keySeparator: " ",
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
