// doc mobX
// https://blog.logicwind.com/quick-easy-mobx-tutorial-in-reactnative/
// enlever avertisement https://ihatetomatoes.net/how-to-remove-experimentaldecorators-warning-in-vscode/
// https://flutterq.com/syntax-error-support-for-the-experimental-syntax-decorators-legacy-isnt-currently-enabled/

import React from "react";
import { observable, action, computed } from "mobx";
import HwApiAmbassador from "../ambassadors/HwApi";

class UserStore {
	@observable UserName = "White Walker";
	@observable UserServer = 75;
	@observable UserServerName = "Hardan's White Sun";
	@observable UserTranslation = "fr-BE";
	@observable UserConnected = false;

	@observable UserCountry = "";
	@observable UserIp = "";

	@observable likesCount = 12;

	@observable comments = ["Wow", "awesome"];

	@action updateServerID(Server) {
		this.UserServer = Server;
	}

	@action updateLanguage(LangIso) {
		this.UserTranslation = LangIso;
	}

	@action updateServerName(ServerName) {
		this.UserServerName = ServerName;
	}

	@action updateUserName(UserName) {
		this.UserName = UserName;
	}

	@action updateStatUser(Country, Ip) {
		if (this.UserIp !== Ip && Ip !== undefined) {
			this.UserCountry = Country;
			this.UserIp = Ip;
			new HwApiAmbassador().postStatUser(Country, Ip, (response) => {});
		}
	}

	@action postComment(comment) {
		this.comments.push(comment);
	}

	@computed get commentsCount() {
		return this.comments.length;
	}
}

const store = new UserStore();
export default store;
