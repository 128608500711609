import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Image, Tab, Row, Col, ListGroup } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";
import HwApiAmbassador from "../../ambassadors/HwApi";
import PageFooter from "../../components/PageFooter";

//import "./../../css/Page.css";
import ImgExit from "./../../Img/Close.png";

import { UserContext } from "./../../Main";

// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";

import GuildSilver from "./Section/GuildSilver";

import "./AdminGw.css";

const AdminGW = () => {
	const history = useHistory();
	const { t } = useTranslation("ui");
	const { server } = useParams();
	const userStore = useContext(UserContext);
	const [data, setData] = useState([]);
	useEffect(() => {
		new HwApiAmbassador().getServerInfos(userStore.UserServer, (response) => {
			setData(response.data);
		});
		// eslint-disable-next-line
	}, []);

	if (server !== undefined) {
		userStore.updateServerID(server);
		userStore.updateServerName(data.name);
	}

	return (
		<>
			{/* <DndProvider backend={HTML5Backend}> */}
			<section className='PgAdminGw'>
				<div className='pt-5'>
					<Tab.Container
						id='list-group-tabs-example'
						defaultActiveKey='#Silver'
					>
						<Row style={{ marginLeft: "20px", marginTop: "40px" }}>
							<Col sm={4} style={{ marginTop: "-5px" }}>
								<ListGroup>
									<ListGroup.Item action href='#link2' disabled>
										Semaine en cours
									</ListGroup.Item>
									<ListGroup.Item action href='#Silver'>
										{t("League")} {t("Silver")}
									</ListGroup.Item>
									<ListGroup.Item action href='#Bronze'>
										{t("League")} {t("Bronze")}
									</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col sm={8} className='pt-3'>
								<Tab.Content>
									{/* <div className='PageBgFooter' style={{ cursor: "default" }}> */}

									<div
										className='TabsPageTitleHeaderPosition d-flex'
										style={{ zIndex: 10 }}
									>
										<div className='PageTitleHeaderLeft'></div>
										<div className='PageTitleHeaderMain'>
											Admin Classement de guerre de guilde
										</div>
										<div className='PageTitleHeaderRight'></div>
									</div>
									{/* </div> */}

									<Image
										className='float-end TabsPgImgClose'
										src={ImgExit}
										style={{ cursor: "pointer" }}
										onClick={() => history.push("/Home")}
									/>
									<Tab.Pane
										eventKey='#Silver'
										style={{ maxHeight: "533px", height: "533px" }}
									>
										{/* <DndProvider backend={HTML5Backend}> */}
										<GuildSilver Leagues='Silver' />
										{/* </DndProvider> */}
									</Tab.Pane>
									<Tab.Pane
										eventKey='#Bronze'
										style={{ maxHeight: "533px", height: "533px" }}
									>
										{/* <DndProvider backend={HTML5Backend}> */}
										<GuildSilver Leagues='Bronze' />
										{/* </DndProvider> */}
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</div>
			</section>
			{/* </DndProvider> */}

			<PageFooter />
		</>
	);
};

export default AdminGW;
