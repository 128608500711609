import axios from "axios";
//import config from "../config";

class GeoLocalizationAmbassador {
	/**
	 * Store params as member for further use.
	 * @param {Object} params
	 */
	constructor(params = {}) {
		this.params = params;
	}

	/**
	 * Gets the localization information.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getLocalization(onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(
				`https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0`,
				this.params,
			)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}
}
export default GeoLocalizationAmbassador;
