import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UserContext } from "./../../../Contexts/userContext";

import { FaCog } from "react-icons/fa";

import "./AdminBottomRight.css";

const AdminBottomRight = (props) => {
	const history = useHistory();
	const userStore = useContext(UserContext);

	const { t } = useTranslation("ui");

	return (
		<>
			<section name='Btn BottomServer' className='BottomServer d-flex'>
				<span className='AdminBottomRight'>
					<div className='AdminBtnGreen text-center'>{t("Save")}</div>
				</span>
			</section>
		</>
	);
};

export default AdminBottomRight;
