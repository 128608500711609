import React from "react";
import { Image } from "react-bootstrap";

import "./HwBtnHeaderR.css";

const HwBtnHeaderR = (props) => {
	return (
		<>
			<section className='m-1 mx-3 HwBtnHeaderR'>
				<span className='HwBtnHeaderR-TXT align-middle justify-content-center'>
					{props.Content}
				</span>

				<span className='HwBtnHeaderR-ICO'>
					<Image src={"./../../PublicImg/Component/Btn/" + props.srcImg} />
				</span>

				<span className='HwBtnHeaderR-ICOplus'>
					<Image src={"./../../PublicImg/Component/Btn/IcoPlus.png"} />
				</span>
			</section>
		</>
	);
};

export default HwBtnHeaderR;
