import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Image, Col, Row } from "react-bootstrap";

import GeoLocalizationAmbassador from "../../ambassadors/GeoLocalization";

import BtnActionLg from "../Button/HwBtnBrownLg";
import HwAvatar from "./../Button/HwAvatar";

import SelectServer from "./SelectServer";

import "./../Modal/Modal_Md.css";
import "./../Modal/SelectUser.css";

import ImgExit from "./../../Img/Close.png";

import { UserContext } from "./../../Main";

const ModalSelectUser = (props) => {
	const userStore = useContext(UserContext);
	const { t } = useTranslation("ui");

	const [showModalSelectServer, setShowSelectServer] = useState(false);

	const [geoLocalization, setGeoLocalization] = useState([]);
	useEffect(() => {
		new GeoLocalizationAmbassador().getLocalization((response) => {
			setGeoLocalization(response.data);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<section>
				<Modal
					id='modal-md'
					size='md'
					show={props.show}
					centered
					scrollable
					backdrop='static'
					keyboard={false}
				>
					<Modal.Header>
						<div className='TitleHeaderPosition'>
							<div className='TitleHeader'>{userStore.UserName}</div>
						</div>
						<Image
							className='ImgClose'
							src={ImgExit}
							onClick={() => props.setShowSelectUser()}
							style={{ cursor: "pointer" }}
						/>

						{/* Account */}
						<Row className='mx-2 mt-4 CustomBox p-0 pt-2 pb-2'>
							<Col className='text-center' style={{ cursor: "default" }}>
								<HwAvatar ImgAvatar='titan_160_4003.png' />
							</Col>
							<Col className='text-center p-0'>
								<BtnActionLg Content='Changer de Nom' />
								<BtnActionLg Content="Changer d'avatar" />
								<BtnActionLg Content="Changer d'encadrement" />
							</Col>
						</Row>

						{/* Geolocalisation */}
						<Row className='mx-2 mt-4 CustomBox p-0 pt-3 SectionGeolocalization'>
							<Col xs={4} style={{ color: "#FFE4B7" }}>
								<p>Country</p>
								<p>City</p>
								<p>State</p>
								<p>IP</p>
							</Col>
							<Col xs={8} className='p-0'>
								<p>{geoLocalization.country_name}</p>
								<p>{geoLocalization.city}</p>
								<p>{geoLocalization.state}</p>
								<p>{geoLocalization.IPv4}</p>
							</Col>
						</Row>

						{/* Select Server */}
						<Row className='mx-2 mt-4 CustomBox p-0 pt-3 SectionGeolocalization'>
							<Col xs={4} style={{ color: "#FFE4B7" }}>
								<p>{t("You Server")} </p>
							</Col>
							<Col xs={8} className='p-0 font-weight-bold'>
								<p>
									{userStore.UserServer} {t(userStore.UserServerName)}
								</p>
							</Col>

							<Col xs={{ span: 10, offset: 2 }} className='p-0 pb-2'>
								<span
									onClick={() => {
										props.setShowSelectUser();
										setShowSelectServer(true);
									}}
								>
									<BtnActionLg Content='Changer de serveur' />
								</span>
							</Col>
						</Row>
					</Modal.Header>
					<Modal.Body className='mb-3'></Modal.Body>
				</Modal>

				<SelectServer
					show={showModalSelectServer}
					setShowSelectServeurs={setShowSelectServer}
				/>
			</section>
		</>
	);
};

export default ModalSelectUser;
