import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Image, Col, Row, Form } from "react-bootstrap";
import HwApiAmbassador from "../../ambassadors/HwApi";
import { useTranslation } from "react-i18next";
import i18n from "../../Middleware/i18n";
import i18next from "i18next";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectLanguage.css";

import ImgExit from "./../../Img/Close.png";

import { UserContext } from "./../../Main";
import { Color } from "ag-grid-community";

const EditGuild = (props) => {
	const userStore = useContext(UserContext);
	const { t } = useTranslation("ui");

	console.log(props.card);

	function onSave(cards) {
		//// TODO
		// console.log("Save");
		// console.log(cards);
	}

	return (
		<>
			<section className=''>
				<Modal
					size='xs'
					show={props.show}
					//onHide={props.setSelectServeurs(false)}
					centered
					scrollable
					backdrop='static'
					keyboard={false}
				>
					<Modal.Header>
						<Image
							className='ImgClose'
							src={ImgExit}
							onClick={() => props.setShowModalEditGuild()}
							style={{ cursor: "pointer", marginLeft: "455px" }}
						/>
						<div className='TitleHeaderPosition'>
							<div className='TitleHeader'>Change Guild</div>
						</div>
					</Modal.Header>
					<Modal.Body className='my-3 px-3 pt-3'>
						<Form.Label style={{ color: "#DF9D63" }}>Ranking</Form.Label>
						<Form.Control
							size='sm'
							type='text'
							value={props.card?.ranking}
							placeholder='ranking'
						/>

						<Form.Label className='mt-2' style={{ color: "#DF9D63" }}>
							Guild Name
						</Form.Label>
						<Form.Control
							size='sm'
							type='text'
							placeholder={props.card?.name}
						/>

						<Form.Label className='mt-2' style={{ color: "#DF9D63" }}>
							League
						</Form.Label>
						<Form.Select size='sm'>
							<option value='Gold'>Gold</option>
							<option value='Silver'>Silver</option>
							<option value='Bronze'>Bronze</option>
							<option value={props.card?.leagues} selected>
								{props.card?.leagues}
							</option>
						</Form.Select>
						<br />
						<div className='AdminBtnGreen text-center float-end'>
							{t("Save")}
						</div>
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</section>
		</>
	);
};

export default EditGuild;
