import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";

import BtnGreen from "./../Button/BtnGreen";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectServer.css";

const ModalGuildRules = (props) => {
	const { server } = useParams("63");

	const [serverCurrent, setServerCurrent] = useState("63");
	if (server !== undefined && serverCurrent !== server) {
		setServerCurrent(server);
	}

	return (
		<>
			<section>
				<Modal size='lg' show={props.show} scrollable backdrop='static' keyboard={false}>
					<div className='d-flex align-items-center justify-content-center'>
						<div className='ContentPolicy ContentPolicyBg px-3 text-center'>
							<h4 className='ContentPolicyTitle pt-3 '>Guild Rules</h4>
							<p className='py-4' style={{ marginTop: "20px" }}>
								This content is not affiliated with NEXTERS, <br />
								is not supported, is not sponsored or approved by it , and NEXTERS is not responsible for it.
							</p>
							<p className='py-4' style={{ marginTop: "20px" }}>
								Ce contenu n'est pas affilié à NEXTERS, <br />
								n'est pas pris en charge, n'est ni parrainé ni approuvé par celui-ci, et NEXTERS n'en est pas responsable.
							</p>

							<div className='d-flex justify-content-center mb-5' style={{ marginTop: "20px" }}>
								<BtnGreen Content='OK' pushPage={"/Home"} className='my-5' />
							</div>
						</div>
					</div>
				</Modal>
			</section>
		</>
	);
};

export default ModalGuildRules;
