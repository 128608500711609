import React from "react";
import ReactDOM from "react-dom";

import "./Middleware/i18n";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/Index.css";

ReactDOM.render(
	<React.StrictMode>
		<DndProvider backend={HTML5Backend}>
			<App />
		</DndProvider>
	</React.StrictMode>,
	document.getElementById("root"),
);
