import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HwBtnCircle from "../../../components/Button/HwBtnCircle";

import { UserContext } from "./../../../Contexts/userContext";

import "./SectionBottomCenter.css";

const BottomCenter = () => {
	const history = useHistory();
	const userStore = useContext(UserContext);

	const { t } = useTranslation("ui");

	return (
		<>
			<section className='BottomCenter d-flex justify-content-center'>
				<span className='BottomCenterL' style={{ paddingLeft: "55px" }} onClick={() => history.push("/GW/Silver")}>
					<HwBtnCircle TxtLabel={t("GW")} ImgLabel='/Bottom/Citadelle.png' FrameLabel='FramePurple.png' />
				</span>
				<span className='BottomCenterM' style={{ paddingLeft: "8px" }} onClick={() => history.push("/GuildRules")}>
					<HwBtnCircle TxtLabel='Guild Rules' ImgLabel='/Bottom/ROI.png' FrameLabel='FrameGreen.png' />
				</span>
				<span transition='glide-top' className='BottomCenterR' style={{ paddingLeft: "8px" }} onClick={() => history.push("/Adventures")}>
					<HwBtnCircle TxtLabel={t("Adventures")} ImgLabel='/Bottom/Sanctuary_Icon.png' FrameLabel='FrameBlue.png' />
				</span>
			</section>
		</>
	);
};

export default BottomCenter;
