import React from "react";
import { Image } from "react-bootstrap";

import "./HwLabelSm.css";

const HwLabelSm = (props) => {
	return (
		<>
			<section className='m-1 HwLabelSm'>
				<span className='HwLabelSm-TXT align-middle justify-content-center'>{props.Content}</span>
			</section>
		</>
	);
};

export default HwLabelSm;
