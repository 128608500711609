import axios from "axios";
//import config from "../config";

class HwApiAmbassador {
	/**
	 * Store params as member for further use.
	 * @param {Object} params
	 */
	constructor(params = {}) {
		this.params = params;
	}

	/**
	 * Gets guilds the server id.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getListGuildsServer(id, onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/servers/${id}`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	/**
	 * Gets guilds the server id.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getListGuildsServerLeagues(
		id,
		leagues,
		onSuccess,
		onError = () => {},
		then = () => {},
	) {
		axios
			.get(
				`https://hw.jmgweb.services/api/servers/${id}/${leagues}`,
				this.params,
			)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	/**
	 * Gets the server id information.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getListServer(onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/servers`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	/**
	 * Gets the server information.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getServerInfos(id, onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/servers/infos/${id}`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	/**
	 * Gets the gw silver information.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getGwSilver(server, onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/gw/${server}/Silver/`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	/**
	 * Gets the gw silver information.
	 * @param {function} onSuccess
	 * @param {function} onError
	 * @param {function} then
	 */
	getGwBronze(server, onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/gw/${server}/Bronze/`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	getStatUser(onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/stat`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	postStatUser(Country, Ip, onSuccess, onError = () => {}, then = () => {}) {
		axios
			.get(`https://hw.jmgweb.services/api/stat/${Country}/${Ip}/`, this.params)
			.then((response) => onSuccess(response))
			.catch((error) => onError(error))
			.finally(() => then());
	}

	// postUpdateGuildOld(
	// 	Server,
	// 	League,
	// 	Ranking,
	// 	onSuccess,
	// 	onError = () => {},
	// 	then = () => {},
	// ) {
	// 	axios
	// 		.get(`https://hw.jmgweb.services/api/stat/${Country}/${Ip}/`, this.params)
	// 		.then((response) => onSuccess(response))
	// 		.catch((error) => onError(error))
	// 		.finally(() => then());
	// }

	postUpdateGuild(
		Id,
		Server,
		League,
		Guild,
		Ranking,
		onError = () => {},
		then = () => {},
	) {
		console.log(
			"axios " + Id + "-" + Server + "-" + League + "-" + Guild + "-" + Ranking,
		);
		axios
			.get(
				`https://hw.jmgweb.services/api/servers/${Id}/${Server}/${League}/${Ranking}/${Guild}`,
				this.params,
			)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	}
}
export default HwApiAmbassador;
