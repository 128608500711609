import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import HwApiAmbassador from "../../ambassadors/HwApi";
import GeoLocalizationAmbassador from "../../ambassadors/GeoLocalization";

import BtnGreenXs from "../../components/Button/BtnGreenXs";

import HwAvatar from "../../components/Button/HwAvatar";
import HwBtnAside from "../../components/Button/HwBtnAside";
import HwLabelSm from "../../components/Button/HwLabelSm";

// import BtnHeaderRight from "./../components/BtnHeaderRight";
import HwBtnHeaderR from "../../components/Button/HwBtnHeaderR";

import BottomLeft from "./Section/SectionBottomLeft";
import BottomCenter from "./Section/SectionBottomCenter";
import BottomRight from "./Section/SectionBottomRight";

// import BottomServer from "../../components/Btn/BottomServer";
// import HwBtnGuild from "../../components/Button/HwBtnGuild";
// import BottomCenter from "../../components/Bottom/BottomCenter";

import SelectServer from "../../components/Modal/SelectServer";
import SelectUser from "../../components/Modal/SelectUser";
import SelectLanguage from "../../components/Modal/SelectLanguage";

import SelectAdventures from "../../components/Modal/SelectAdventures";

import { UserContext } from "../../Main";

import "./../../css/Page.css";
import "./../../css/Section.css";
import "./../../css/Component.css";
import "./Home.css";
import HwBtnGuild from "../../components/Button/HwBtnGuild";

import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";

const Home = (props) => {
	//const history = useHistory();

	const userStore = useContext(UserContext);

	const { t } = useTranslation("ui");

	const { server } = useParams();
	const [serverCurrent, setServerCurrent] = useState("63");
	if (server !== undefined && serverCurrent !== server) {
		setServerCurrent(server);
	}

	const [showModalSelectUser, setShowSelectUser] = useState(false);
	const [showModalSelectServer, setShowSelectServer] = useState(false);
	const [showModalSelectLanguage, setShowSelectLanguage] = useState(false);
	const [showModalSelectAdventures, setShowSelectAdventures] = useState(false);

	const [geoLocalization, setGeoLocalization] = useState([]);
	useEffect(() => {
		new GeoLocalizationAmbassador().getLocalization((response) => {
			setGeoLocalization(response.data);
			userStore.updateStatUser(response.data.country_name, response.data.IPv4);
		});
		// eslint-disable-next-line
	}, []);

	const [serverInfos, setServerInfos] = useState([]);
	useEffect(() => {
		new HwApiAmbassador().getServerInfos(userStore.UserServer, (response) => {
			setServerInfos(response.data);
		});
		// eslint-disable-next-line
	}, []);

	const [statUsers, setStatUsers] = useState("Loading");
	useEffect(() => {
		new HwApiAmbassador().getStatUser((response) => {
			setStatUsers(response.data);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<PageHeader />

			<section className='PgHome' style={{ height: "640px" }}>
				<div className='d-flex pt-2'>
					<div className='text-center'>
						<div>
							<HwBtnGuild TxtLabel={"HWA"} ImgLabel='/Bottom/FagnonAcademy.png' FrameLabel='FrameBrowA.png' />
							{/* <HwAvatar ImgAvatar='titan_160_4003.png' /> */}
						</div>

						{/* <div onClick={() => setShowSelectUser(true)} style={{ marginTop: "115px" }}> */}
						<div style={{ marginTop: "115px" }}>
							<HwLabelSm Content={t("GM") + " " + userStore.UserName} />
						</div>
					</div>
					<div className='px-4 pt-2'>
						<HwBtnAside Content='Mise à jour' srcImg='IcoCalendar.png' />
						<HwBtnAside Content='Discord' srcImg='IcoMsg.png' />
						<HwBtnAside Content='Cadeaux de page' srcImg='IcoGift.png' />
					</div>
				</div>

				<section className='section-BtnHeaderR'>
					<div className='d-flex pt-2'>
						<HwBtnHeaderR Content='-200' srcImg='IcoOr.png' />
						<HwBtnHeaderR Content={statUsers} srcImg='IcoEnergy.png' />
					</div>
				</section>

				<Row className='fixed-bottom-Custom'>
					<Col>
						<section name='section-BottomLeft'>
							<span onClick={() => setShowSelectServer(true)}>
								<BottomLeft />
							</span>
						</section>
					</Col>
					<Col>
						<section name='section-BottomCenter'>
							<BottomCenter />
						</section>
					</Col>
					<Col>
						<section name='section-BottomRight'>
							{/* <span onClick={() => setShowSelectLanguage(true)}>
								<BottomRight />
							</span> */}
						</section>
					</Col>
				</Row>
			</section>

			<PageFooter />

			<SelectUser show={showModalSelectUser} setShowSelectUser={setShowSelectUser} serverName={serverInfos.name} />
			<SelectServer show={showModalSelectServer} setShowSelectServeurs={setShowSelectServer} />
			<SelectLanguage show={showModalSelectLanguage} setShowSelectLanguage={setShowSelectLanguage} />
			<SelectAdventures show={showModalSelectAdventures} setShowSelectAdventures={setShowSelectAdventures} />
		</>
	);
};

export default Home;
