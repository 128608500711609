import React, { useEffect, useState, useCallback, useContext } from "react";
import { Image, Tab, Row, Col, ListGroup } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { Card } from "./Card.js";
import update from "immutability-helper";
import { ItemTypes } from "./ItemTypes.js";

import { FaEdit } from "react-icons/fa";

import BottomLeft from "./../../Home/Section/SectionBottomLeft";
import BottomRight from "./AdminBottomRight";
import SelectServer from "../../../components/Modal/SelectServer";

import ModalEditGuild from "../../../components/Modal/EditGuild";

import HwApiAmbassador from "./../../../ambassadors/HwApi";

import { UserContext } from "./../../../Main";

const style = {
	width: 390,
};
const ITEMS = [
	{
		id:1000,
		ranking: 1000,
		name: "Loading",
	},
];

const GwSilver = (props) => {
	const userStore = useContext(UserContext);
	const [showModalSelectServer, setShowSelectServer] = useState(false);
	const [showModalEditGuild, setShowModalEditGuild] = useState(false);

	const [cards, setCards] = useState(ITEMS);
	const [card, setCard] = useState();

	useEffect(() => {
		new HwApiAmbassador().getListGuildsServerLeagues(
			userStore.UserServer,
			props.Leagues,
			(response) => {
				setCards(response.data);
			},
		);
		// eslint-disable-next-line
	}, [userStore.UserServer]);

	const findCard = useCallback(
		(ranking) => {
			const card = cards.filter((c) => `${c.ranking}` === ranking)[0];
			return {
				card,
				index: cards.indexOf(card),
			};
		},
		[cards],
	);
	const moveCard = useCallback(
		(ranking, atIndex) => {
			const { card, index } = findCard(ranking);
			setCards(
				update(cards, {
					$splice: [
						[index, 1],
						[atIndex, 0, card],
					],
				}),
			);
		},
		[findCard, cards, setCards],
	);
	const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

	function onSave(cards) {
		//// TODO
		// console.log("Save");
		// console.log(cards);
		for (let i = 0; i < cards.length; i++) {
			cards[i].ranking = i + 1;
			//persons.push(<p>{data[i].name + ", " + data[i].age + " years old"}</p>)
			new HwApiAmbassador().postUpdateGuild(
				cards[i].id,
				cards[i].server,
				cards[i].leagues,
				cards[i].name,
				cards[i].ranking,
				(response) => {
					//setCards(response.data);
				},
			);
			// eslint-disable-next-line
		}
	}

	function EditGuild(card) {
		setCard(card);
		setShowModalEditGuild(true);
	}

	return (
		<>
			<Row className='fixed-bottom-Custom' style={{ height: "10px" }}>
				<Col style={{ marginTop: "-90px" }}>
					<span onClick={() => setShowSelectServer(true)}>
						<BottomLeft />
					</span>
				</Col>
				<Col></Col>
				<Col style={{ marginTop: "-100px" }}>
					<section name='section-BottomRight'>
						<span onClick={() => onSave(cards)}>
							<BottomRight />
						</span>
					</section>
				</Col>
			</Row>
			<div ref={drop} style={style} className='pt-2 pb-2'>
				{cards.map((card) => (
					<>
						<Row className='listCards-item'>
							<Col xs={10}>
								<Card
									key={card.ranking}
									ranking={`${card.ranking}`}
									name={card.name}
									moveCard={moveCard}
									findCard={findCard}
								/>
							</Col>
							{/* <Col xs={2}>
								<span onClick={() => EditGuild(card)}>
									<FaEdit />
								</span>
							</Col> */}
						</Row>
					</>
				))}
			</div>

			<SelectServer
				show={showModalSelectServer}
				setShowSelectServeurs={setShowSelectServer}
			/>

			<ModalEditGuild
				show={showModalEditGuild}
				setShowModalEditGuild={setShowModalEditGuild}
				card={card}
			/>
		</>
	);
};

export default GwSilver;
