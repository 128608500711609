import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
const style = {
	border: "1px dashed #DF9D63",
	padding: "0.5rem",
	marginBottom: "0.25rem",
	cursor: "move",
};

export const Card = memo(function Card({ ranking, name, moveCard, findCard }) {
	const originalIndex = findCard(ranking).index;
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.CARD,
			item: { ranking, originalIndex },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				const { ranking: droppedId, originalIndex } = item;
				const didDrop = monitor.didDrop();
				if (!didDrop) {
					moveCard(droppedId, originalIndex);
				}
			},
		}),
		[ranking, originalIndex, moveCard],
	);
	const [, drop] = useDrop(
		() => ({
			accept: ItemTypes.CARD,
			hover({ ranking: draggedId }) {
				if (draggedId !== ranking) {
					const { index: overIndex } = findCard(ranking);
					moveCard(draggedId, overIndex);
				}
			},
		}),
		[findCard, moveCard],
	);
	const opacity = isDragging ? 0 : 1;
	return (
		<div
			className=''
			ref={(node) => drag(drop(node))}
			style={{ ...style, opacity }}
		>
			{originalIndex + 1} - {name}
		</div>
	);
});
