import React from "react";
import { Image } from "react-bootstrap";

import LabelXs from './HwLabelXs';

import "./HwBtnCircle.css";

const HwBtnCircle = (props) => {

	return (
		<>
			<section className="HwBtnCircle text-center">				
			{/* <Image className='HwBtnCircle-ICO'  src="./../../PublicImg/Avatars/titan_160_4003.png" /> */}
			<Image className='HwBtnCircle-Frame'  src={"./../../PublicImg/Frame/" + props.FrameLabel}  />
			<span className="text-center align-self-center">
			{/* <Image className='HwBtnCircle-ICO'  src="./../../PublicImg/Sanctuary_Icon.png" /> */}
			<Image className='HwBtnCircle-ICO'  src={"./../../PublicImg" + props.ImgLabel} />
			
			<LabelXs TxtLabel={props.TxtLabel} />
			</span>

			</section>
		</>
	);
};

export default HwBtnCircle;