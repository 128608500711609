import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Image } from "react-bootstrap";
import HwApiAmbassador from "../../ambassadors/HwApi";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectServer.css";

import ImgExit from "./../../Img/Close.png";

const ModalSelectAdventures = (props) => {
	const [data, setData] = useState([]);
	const history = useHistory();
	useEffect(() => {
		new HwApiAmbassador().getListServer((response) => {
			setData(response.data);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<section>
				<Modal
					size='lg'
					show={props.show}
					//onHide={handleClose}
					centered
					scrollable
					backdrop='static'
					keyboard={false}
				>
					<Modal.Header>
						<Image
							className='ImgClose'
							src={ImgExit}
							onClick={() => props.setShowSelectAdventures()}
							style={{ cursor: "pointer" }}
						/>
						<div className='TitleHeaderPosition'>
							<div className='TitleHeader'>Aventures</div>
						</div>
						<div className='text-center Texte-yellow font-weight-bold'>
							<h6>Liste de serveurs</h6>
						</div>
						<div className='listServer-head'>
							<span className='ForceMarge'>id</span>
							Nom de server
							<span className='float-end small'>Coach</span>
						</div>
					</Modal.Header>
					<Modal.Body className='mb-3'>
						{data.map((item, i) => (
							<>
								{item.moderateur !== "" && (
									<div
										className='listServer-item'
										action
										onClick={() => history.push("/Adventures/" + item.id)}
									>
										<span className='ForceMarge'>{item.id}</span>
										{item.name}
										<span className='float-end small'>{item.moderateur}</span>
									</div>
								)}
								{item.moderateur === "" && (
									<div
										className='listServer-item'
										action
										onClick={() => history.push("/Adventures/" + item.id)}
									>
										<span className='ForceMarge'>{item.id}</span>
										{item.name}
										<span className='float-end small'>- ? -</span>
									</div>
								)}
							</>
						))}
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</section>
		</>
	);
};

export default ModalSelectAdventures;
