import React, { useEffect, useState, useCallback } from "react";
import { Image } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CopyToClipboard } from "react-copy-to-clipboard";

import ViewAdventuresMap from "../components/Modal/ViewAdventuresMap";

import { BiCopyAlt } from "react-icons/bi";
import { MdImageSearch } from "react-icons/md";

import ImgExit from "./../Img/Close.png";

import ArrowLeft from "./../Img/Adventures/Left.png";
import ArrowRight from "./../Img/Adventures/Right.png";

import "./../css/Page.css";
import "./../css/Adventures.css";
import "./../css/Component.css";

import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";

const Adventures = () => {
	const history = useHistory();

	const { t } = useTranslation("ui");

	let { id } = useParams(1);
	if (id === undefined || Number(id) <= 0) {
		id = 1;
	}
	if (id === undefined || Number(id) > 12) {
		id = 12;
	}

	const [ForceAdventuresID, setForceAdventureID] = useState(Number(id));
	const [showModalViewMap, setShowModalViewMap] = useState(false);

	const [AdventureID, setAdventureID] = useState(ForceAdventuresID);
	const [AdventureName, setAdventureName] = useState("");
	const [AdventureLevel, setAdventureLevel] = useState("");
	const [AdventuresImg, setAdventureImg] = useState("./../PublicImg/Adventures/" + ForceAdventuresID + ".jpg");
	const [AdventurePathCopied, setAdventurePathCopied] = useState();

	const [showCopied, setCopied] = useState("invisible");

	const onClick = ({ target: { innerHTML } }) => {
		var regex = /<br>/gi;
		var cleanupPath = innerHTML.replaceAll(regex, "\n");
		cleanupPath = cleanupPath.replaceAll(/<[^>]+>/g, "");
		regex = /<text>/gi;
		cleanupPath = cleanupPath.replaceAll(regex, "");
		regex = /<\/text>/gi;
		cleanupPath = cleanupPath.replaceAll(regex, "");

		setAdventurePathCopied(cleanupPath);
		setCopied("");
	};

	useEffect(() => {
		switch (AdventureID) {
			case 1:
				setAdventureName(t("Siege of Strongford"));
				setAdventureLevel("40");
				setAdventurePathCopied("Blue : 02-03-05-Boss(x2)" + "\n" + "Orange : 02-04-07-Boss(x2)" + "\n");
				break;

			case 2:
				setAdventureName(t("Valley of the Elements"));
				setAdventureLevel("45");
				setAdventurePathCopied("Blue : 03-06-09-Boss(x2)" + "\n" + "Orange : 02-05-08-09-Boss" + "\n" + "Green : 04-07-10-09-Boss" + "\n");
				break;
			case 3:
				setAdventureName(t("Ghirwil City Depths"));
				setAdventureLevel("50");
				setAdventurePathCopied("Blue : 04-12-13-Boss(x2)" + "\n" + "Orange : 05-06-09-Boss(x2)" + "\n" + "Green : 02-03-07-10-Boss");
				break;
			case 4:
				setAdventureName(t("Fall of the Celestial City"));
				setAdventureLevel("55");
				setAdventurePathCopied("Blue : 02-04-07-18-08-12-19-22-Boss(x2)" + "\n" + "Orange : 05-24-25-09-14-13-20-22-Boss(x2)" + "\n" + "Green : 03-06-11-17-10-16-21-22-Boss(x2)");
				break;
			case 5:
				setAdventureName(t("Siege of Strongford"));
				setAdventureLevel("60");
				setAdventurePathCopied("Blue : 05-09-10-14-17-20-27-25-21-Boss" + "\n" + "Orange : 02-07-08-12-16-23-26-25-22" + "\n" + "Green : 04-06-10-11-15-19-18-Boss(x3)");
				break;
			case 6:
				setAdventureName(t("Valley of the Elements"));
				setAdventureLevel("70");
				setAdventurePathCopied("Blue : 02-04-07-10-13-16-19-24-22-Boss" + "\n" + "Orange : 05-07-08-11-14-17-20-22-23-Boss(x2)" + "\n" + "Green : 03-06-09-12-15-18-21-26-Boss(x2)");
				break;
			case 7:
				setAdventureName(t("Ghirwil City Depths"));
				setAdventureLevel("80");
				setAdventurePathCopied("Blue : 08-01-11-12-15-12-11-21-25-Boss" + "\n" + "Orange : 11-10-14-17-13-19-20-24-Boss(x2)" + "\n" + "Green : 07-03-04-05-09-16-23-22-26-Boss");
				break;
			case 8:
				setAdventureName(t("Fall of the Celestial City"));
				setAdventureLevel("90");
				setAdventurePathCopied("Blue : 03-02-06-07-09-10-13-17-16-20-22-21-28-Boss(x2)" + "\n" + "Orange : 03-05-07-09-11-14-18-20-22-24-27-30-26-29-25" + "\n" + "Green : 03-04-08-07-09-11-15-19-20-22-23-31-Boss(x3)");
				break;
			case 9:
				setAdventureName(t("Siege of Strongford"));
				setAdventureLevel("100");
				setAdventurePathCopied("Blue : 02-06-10-15-20-14-24-29-25-36-39-42-44-Boss(x2)" + "\n" + "Orange : 03-08-12-11-07-16-21-26-30-31-32-35-37-40-Boss" + "\n" + "Green : 03-04-13-19-18-23-17-22-38-41-43-46-Boss(x3)");
				break;
			case 10:
				setAdventureName(t("Valley of the Elements"));
				setAdventureLevel("110");
				setAdventurePathCopied("Blue : 03-02-06-11-17-25-30-35-34-29-24-21-17-12-07" + "\n" + "Orange : 04-08-13-18-22-26-31-36-40-45-44-43-38-33-28" + "\n" + "Green : 05-09-14-19-23-27-32-37-42-48-51-50-49-46-Boss");
				break;
			case 11:
				setAdventureName(t("Ghirwil City Depths"));
				setAdventureLevel("120");
				setAdventurePathCopied("Blue : 02-03-06-07-12-11-15-21-27-36-39-40-41-Boss(x2)" + "\n" + "Orange : 02-04-06-08-12-17-16-22-28-29-30-31-25-19-18" + "\n" + "Green : 02-05-06-09-13-14-20-26-32-38-35-33-34-Boss(x2)");
				break;
			case 12:
				setAdventureName(t("Fall of the Celestial City"));
				setAdventureLevel("130");
				setAdventurePathCopied("Blue : 09-03-06-10-22-31-30-21-30-31-36-35-29-34-25" + "\n" + "Orange : 05-12-15-28-20-12-14-26-18-19-20-Boss(x4)" + "\n" + "Green : 08-02-04-07-16-23-32-33-25-24-17-11");
				break;
			default:
				setAdventureName("-- Name? --");
				setAdventureLevel("-- Level? --");
				setAdventurePathCopied("-- Id? --");
		}
	}, [AdventureID]);

	const IncrementItem = useCallback(() => {
		if (Number(id) !== undefined) {
			history.push("/Adventures");
		}
		let CountAdventure = AdventureID + 1;
		if (CountAdventure > 12) {
			CountAdventure = 1;
		}
		setForceAdventureID(CountAdventure);
		setAdventureID(CountAdventure);
		setAdventureImg("./../PublicImg/Adventures/" + CountAdventure + ".jpg");
		setCopied("invisible");
	});

	const DecreaseItem = useCallback(() => {
		if (Number(id) !== undefined) {
			history.push("/Adventures");
		}
		let CountAdventure = AdventureID - 1;
		if (CountAdventure < 1) {
			CountAdventure = 12;
		}
		setForceAdventureID(CountAdventure);
		setAdventureID(CountAdventure);
		setAdventureImg("./../PublicImg/Adventures/" + CountAdventure + ".jpg");
		setCopied("invisible");
	});

	const escFunction = useCallback(
		(event) => {
			switch (event.keyCode) {
				case 27:
					if (showModalViewMap) {
						setShowModalViewMap(false);
					} else {
						history.push("/Home");
					}

					break;
				case 37:
					DecreaseItem();
					break;
				case 39:
					IncrementItem();
					break;
				default:
					console.log("Presse Key : " + event.keyCode);
					break;
			}
		},
		[DecreaseItem, IncrementItem, history, showModalViewMap]
	);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	const handleOpenModal = () => {
		setCopied("invisible");
		setShowModalViewMap(true);
	};

	if (ForceAdventuresID !== undefined && ForceAdventuresID !== AdventureID) {
		setAdventureID(ForceAdventuresID);
		setAdventureImg("./../PublicImg/Adventures/" + ForceAdventuresID + ".jpg");
		setCopied("invisible");
	}

	return (
		<>
			<PageHeader />

			<section className='PgAdventures' style={{ height: "640px" }}>
				<div className='d-flex pt-2'>
					{!showModalViewMap && (
						<>
							<div>
								<Image className='float-end PgImgClose' src={ImgExit} style={{ cursor: "pointer" }} onClick={() => history.push("/Home")} />

								<div className='AdventureDeco'></div>

								<div className='float-end d-flex SelectAdventure'>
									<Image className='SelectArrowLeft' src={ArrowLeft} style={{ cursor: "pointer" }} onClick={DecreaseItem} />
									<div className='mx-2 text-center' style={{ paddingTop: "10px" }}>
										<p className='AdventureName'>
											<div className='d-flex text-center'>
												<div className='LeftDeco'></div>
												<div
													style={{
														width: "20px",
														textAlign: "center",
													}}>
													{AdventureID}
												</div>
												<div className='mr-2 RightDeco'></div>
											</div>

											<div>
												{AdventureName}
												<p className='AdventureLevel'>
													{t("Level of teams required")} {AdventureLevel}
												</p>
											</div>
										</p>
									</div>

									<Image className='SelectArrowRight' src={ArrowRight} style={{ cursor: "pointer" }} onClick={IncrementItem} />
								</div>

								{/* <div className="separator"></div> */}

								<div className={`Copied ${showCopied}`}>Copied</div>

								<div className='float-end mt-3 AdventurePathNumber'>
									<CopyToClipboard
										//options={{ debug: props.debug, message: "OK debug" }}
										//text={copiedAdventuresPath}
										//onCopy={onClick}
										text={AdventurePathCopied}>
										<div className='text-left text-white w-100 mx-2'>
											<span className='BtnCopiedMap' onClick={onClick}>
												<BiCopyAlt className='BtnCopiedMapIcon' />
											</span>

											{(() => {
												switch (AdventureID) {
													case 1:
														return (
															<text className='debugplacement'>
																Blue : 02-03-05-Boss(x2)
																<br />
																Orange : 02-04-07-Boss(x2)
																<br />
															</text>
														);
													case 2:
														return (
															<text className='debugplacement'>
																Blue : 03-06-09-Boss(x2)
																<br />
																Orange : 02-05-08-09-Boss
																<br />
																Green : 04-07-10-09-Boss
																<br />
															</text>
														);
													case 3:
														return (
															<text className='debugplacement'>
																Blue : 04-12-13-Boss(x2)
																<br />
																Orange : 05-06-09-Boss(x2)
																<br />
																Green : 02-03-07-10-Boss
															</text>
														);
													case 4:
														return (
															<text className='debugplacement'>
																Blue : 02-04-07-18-08-12-19-22-Boss(x2)
																<br /> Orange : 05-24-25-09-14-13-20-22-Boss(x2)
																<br /> Green : 03-06-11-17-10-16-21-22-Boss(x2)
															</text>
														);
													case 5:
														return (
															<text className='debugplacement'>
																Blue : 05-09-10-14-17-20-27-25-21-Boss
																<br /> Orange : 02-07-08-12-16-23-26-25-22
																<br /> Green : 04-06-10-11-15-19-18-Boss(x3)
															</text>
														);
													case 6:
														return (
															<text className='debugplacement'>
																Blue : 02-04-07-10-13-16-19-24-22-Boss
																<br /> Orange : 05-07-08-11-14-17-20-22-23-Boss(x2)
																<br /> Green : 03-06-09-12-15-18-21-26-Boss(x2)
															</text>
														);
													case 7:
														return (
															<text className='debugplacement'>
																Blue : 08-01-11-12-15-12-11-21-25-Boss
																<br /> Orange : 11-10-14-17-13-19-20-24-Boss(x2)
																<br /> Green : 07-03-04-05-09-16-23-22-26-Boss
															</text>
														);
													case 8:
														return (
															<text className='debugplacement'>
																Blue : 03-02-06-07-09-10-13-17-16-20-22-21-28-Boss(x2)
																<br />
																Orange : 03-05-07-09-11-14-18-20-22-24-27-30-26-29-25
																<br />
																Green : 03-04-08-07-09-11-15-19-20-22-23-31-Boss(x3)
															</text>
														);
													case 9:
														return (
															<text className='debugplacement'>
																Blue : 02-06-10-15-20-14-24-29-25-36-39-42-44-Boss(x2)
																<br />
																Orange : 03-08-12-11-07-16-21-26-30-31-32-35-37-40-Boss
																<br />
																Green : 03-04-13-19-18-23-17-22-38-41-43-46-Boss(x3)
															</text>
														);
													case 10:
														return (
															<text className='debugplacement'>
																Blue : 03-02-06-11-17-25-30-35-34-29-24-21-17-12-07
																<br /> Orange : 04-08-13-18-22-26-31-36-40-45-44-43-38-33-28
																<br /> Green : 05-09-14-19-23-27-32-37-42-48-51-50-49-46-Boss
															</text>
														);
													case 11:
														return (
															<text className='debugplacement'>
																Blue : 02-03-06-07-12-11-15-21-27-36-39-40-41-Boss(x2)
																<br /> Orange : 02-04-06-08-12-17-16-22-28-29-30-31-25-19-18
																<br /> Green : 02-05-06-09-13-14-20-26-32-38-35-33-34-Boss(x2)
															</text>
														);
													case 12:
														return (
															<text className='debugplacement'>
																Blue : 09-03-06-10-22-31-30-21-30-31-36-35-29-34-25
																<br /> Orange : 05-12-15-28-20-12-14-26-18-19-20-Boss(x4)
																<br /> Green : 08-02-04-07-16-23-32-33-25-24-17-11
															</text>
														);
													default:
														return <text className='debugplacement'></text>;
												}
											})()}
										</div>
									</CopyToClipboard>
								</div>
								<div className='text-left text-white w-100 mx-2'>
									<span className='BtnViewMapModal' onClick={() => handleOpenModal()}>
										<MdImageSearch className='BtnViewMapModalIcon' />
									</span>
								</div>

								<div className='float-end mx-3 AdventuresViewMap'>
									<Image style={{ maxHeight: "273px" }} className='map-img-fluid' src={AdventuresImg} />
								</div>
							</div>
						</>
					)}
				</div>
			</section>

			<PageFooter />

			<ViewAdventuresMap show={showModalViewMap} setShowModalViewMap={setShowModalViewMap} AdventureName={AdventureName} AdventureID={`${AdventureID}`} />
		</>
	);
};

export default Adventures;
