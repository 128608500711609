import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Image, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HwApiAmbassador from "../../ambassadors/HwApi";

import "./../Modal/Modal_Lg.css";
import "./../Modal/SelectServer.css";

import ImgExit from "./../../Img/Close.png";

import { UserContext } from "./../../Main";

const ModalSelectServer = (props) => {
	const userStore = useContext(UserContext);
	const { t } = useTranslation("ui");

	const [data, setData] = useState([]);
	const history = useHistory();
	useEffect(() => {
		let response = require("./../../FakeData/servers.json");
		setData(response);
		// new HwApiAmbassador().getListServer((response) => {
		// 	setData(response.data);
		// });
		// // eslint-disable-next-line
	}, []);

	return (
		<>
			<section>
				<Modal
					size='lg'
					show={props.show}
					//onHide={props.setSelectServeurs(false)}
					centered
					scrollable
					backdrop='static'
					keyboard={false}>
					<Modal.Header>
						<Image className='ImgClose' src={ImgExit} onClick={() => props.setShowSelectServeurs()} style={{ cursor: "pointer" }} />
						<div className='TitleHeaderPosition'>
							<div className='TitleHeader'>{t("Change Server")}</div>
						</div>
						{/* <div className='text-center Texte-yellow font-weight-bold'>
							<h6>Liste de serveurs</h6>
						</div> */}
						<Row className='d-flex listServer-head small mt-2'>
							<Col xs={1}>{t("Server")}</Col>
							<Col xs={9}>{t("Name")}</Col>
							<Col xs={2}>{t("Coach")}</Col>
						</Row>
					</Modal.Header>
					<Modal.Body className='my-3 px-3'>
						{data.map((item, i) => (
							<>
								<Row
									className='d-flex listServer-item'
									key={i}
									action
									onClick={() => {
										userStore.updateUserName(item.moderateur);
										userStore.updateServerID(item.id);
										userStore.updateServerName(item.name);
										props.setShowSelectServeurs();
									}}>
									<Col xs={1}>{item.id}</Col>
									<Col xs={9}>{t(item.name)}</Col>

									{item.moderateur !== "" && <Col xs={2}>{item.moderateur}</Col>}
									{item.moderateur === "" && <Col xs={2}>- ? -</Col>}
								</Row>
							</>
						))}
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</section>
		</>
	);
};

export default ModalSelectServer;
