import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Image } from "react-bootstrap";
import "./../css/Gw.css";
import vSilver from "./../Img/GDG/vSilver.png";
import vBronze from "./../Img/GDG/vBronze.png";

const SelectLeagues = (props) => {
	const history = useHistory();
	const { t } = useTranslation("ui");

	const RenderBtnSilver = (leagues) => {
		if (leagues !== "Silver") {
			return (
				<>
					<span className='vSilverTitle'>
						<Image src={vSilver} />
					</span>
					<Button
						className='mx-5'
						variant='secondary'
						onClick={() => history.push("/GW/Silver")}
					>
						{t("League")} {t("Silver")}
					</Button>
				</>
			);
		} else {
			return (
				<>
					<span className='vSilverTitle'>
						<Image src={vSilver} />
					</span>
					<Button className='mx-5 btn-secondary-selected' variant='secondary'>
						{t("League")} {t("Silver")}
					</Button>
				</>
			);
		}
	};

	const RenderBtnBronze = (leagues) => {
		if (leagues !== "Bronze") {
			return (
				<>
					<span className='vBronze'>
						<Image src={vBronze} />
					</span>
					<Button
						className='mx-5'
						variant='secondary'
						onClick={() => history.push("/GW/Bronze")}
					>
						{t("League")} {t("Bronze")}
					</Button>
				</>
			);
		} else {
			return (
				<>
					<span className='vBronze'>
						<Image src={vBronze} />
					</span>
					<Button className='mx-5 btn-secondary-selected' variant='secondary'>
						{t("League")} {t("Bronze")}
					</Button>
				</>
			);
		}
	};

	return (
		<>
			<div className='d-flex justify-content-center'>
				{RenderBtnSilver(props.leagues)}
				{RenderBtnBronze(props.leagues)}
			</div>
		</>
	);
};

export default SelectLeagues;
