import React from "react";
import { Row, Col } from "react-bootstrap";

import { useParams } from "react-router-dom";

import "./../../css/Page.css";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import ModalContentPolicy from "../../components/Modal/ContentPolicy";
import BottomLeft from "../Home/Section/SectionBottomLeft";
import BottomCenter from "../Home/Section/SectionBottomCenter";
import ModalGuildRules from "../../components/Modal/GuildRules";

const GuildRules = () => {
	const { server } = useParams("63");

	return (
		<>
			<PageHeader />
			<section className='PgContentPolicy'>
				<Row className='fixed-bottom-Custom'>
					<Col>
						<section name='section-BottomLeft'>
							<span>
								<BottomLeft />
							</span>
						</section>
					</Col>
					<Col>
						<section name='section-BottomCenter'>
							<BottomCenter />
						</section>
					</Col>
					<Col>
						<section name='section-BottomRight'>
							{/* <span onClick={() => setShowSelectLanguage(true)}>
								<BottomRight />
							</span> */}
						</section>
					</Col>
				</Row>

				<ModalGuildRules show={true} serverCurrent={server} />
			</section>

			<PageFooter />
		</>
	);
};

export default GuildRules;
