import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//import { UserContext } from "./../../../Contexts/userContext";

import { UserContext } from "./../../../Main";

import LabelXs from "./../../../components/Button/HwLabelXs";

import "./SectionBottomLeft.css";

const BottomLeft = () => {
	const userStore = useContext(UserContext);

	const { t } = useTranslation("ui");

	return (
		<>
			<section name='Btn BottomServer' className='BottomServer'>
				<span className='BottomServer-Txt'>{userStore.UserServer}</span>
				<Image
					className='BottomServer-Frame'
					src={"./../../PublicImg/Frame/KingBlue.png"}
				/>
				<span className='BottomServer-Txt-Position'>
					<LabelXs TxtLabel={t("Server")} />
				</span>
			</section>
		</>
	);
};

export default BottomLeft;
