import React from "react";
import { Image } from "react-bootstrap";

import "./HwAvatar.css";

const HwAvatar = (props) => {

	return (
		<>
			<section style={{height:"110px"}}>
			<Image className='HwAvatar-ICO align-middle justify-content-center'  src={"./../../PublicImg/Avatars/" + props.ImgAvatar} />
			
			<Image className='HwAvatar-Frame align-middle justify-content-center'  src="./../../PublicImg/Avatars/AvatarFrameBlue.png" />
			
			</section>
		</>
	);
};

export default HwAvatar;