import React from "react";
import { Image } from "react-bootstrap";

import "./HwLabelXs.css";

const HwLabelXs = (props) => {

	return (
		<>
			<section className="HwLabelXs">
				
				<span className="HwLabelXs-TXT align-middle justify-content-center">
				{props.TxtLabel}
				</span>

			</section>
		</>
	);
};

export default HwLabelXs;