import uiDe from "./de-DE/ui.json";
import uiEn from "./en-GB/ui.json";
import uiFr from "./fr-BE/ui.json";
import uiNl from "./nl-BE/ui.json";

export const resources = {
	"en-GB": {
		ui: uiEn,
	},
	"fr-BE": {
		ui: uiFr,
	},
	"nl-BE": {
		ui: uiNl,
	},
	"de-DE": {
		ui: uiDe,
	},
};
