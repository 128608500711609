import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import PartnersLeft from "./components/Partners/PartnersLeft";

import Main from "./Main";

// you need to import styles!
// one of:
// styles/main.css
// styles/main.min.css
import "react-tiger-transition/styles/main.min.css";

import "./css/Layout.css";

function Layout() {
	return (
		<>
			<Router>
				{/* Context provider for transitions */}
				<div className='d-flex'>
					{/* <div
						className=''
						style={{
							justifyContent: "center",
							marginLeft: "15px",
							marginTop: "55px",
						}}
					>
						<PartnersLeft />
					</div> */}
					<div className='col-centered'>
						<div className='d-flex flex-row'>
							<div className='MainLeft'></div>
							<div className='Main' style={{ height: "800px !important" }}>
								<section className=''>
									<Main />
								</section>
							</div>
							<div className='MainRight'></div>
						</div>
					</div>
				</div>
				{/* <div className='bg-white'>
					<Partners />
				</div> */}
			</Router>
		</>
	);
}

export default Layout;
