import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Image, Table } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";
import HwApiAmbassador from "../ambassadors/HwApi";
import SelectLeagues from "../components/SelectLeagues";
import PageFooter from "../components/PageFooter";

import "./../css/Page.css";
import ImgExit from "./../Img/Close.png";

import { UserContext } from "./../Main";
import { t } from "i18next";

const GW = () => {
	const history = useHistory();
	const { t } = useTranslation("ui");
	const { server } = useParams();
	const userStore = useContext(UserContext);

	const [data, setData] = useState([]);
	useEffect(() => {
		new HwApiAmbassador().getServerInfos(userStore.UserServer, (response) => {
			setData(response.data);
		});
		// eslint-disable-next-line
	}, []);

	if (server !== undefined) {
		userStore.updateServerID(server);
		userStore.updateServerName(data.name);
	}

	const [dataGw, setDataGw] = useState([]);
	useEffect(() => {
		new HwApiAmbassador().getGwSilver(userStore.UserServer, (response) => {
			setDataGw(response?.data);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<section className='PgGw'>
				<div className='PageBgHeader'></div>
				<div className='PageTitleHeaderPosition d-flex'>
					<div className='PageTitleHeaderLeft'></div>
					<div className='PageTitleHeaderMain'>{t("Guild War")}</div>
					<div className='PageTitleHeaderRight'></div>
				</div>
				<Image
					className='float-end PgImgClose'
					src={ImgExit}
					style={{ cursor: "pointer" }}
					onClick={() => history.push("/Home")}
				/>
				<div className='SelectLeague'>
					<SelectLeagues leagues='Silver' />
					<div className='Server-head my-3' style={{ cursor: "default" }}>
						<span className='ForceMarge'>{userStore.UserServer}</span>
						{t(data.name)}
						<span className='float-end small'>Coach : {data.moderateur}</span>
					</div>
				</div>
				<Table className='small'>
					<thead>
						<tr>
							<th>Ligue Argent </th>
							<th>Lundi</th>
							<th>Mardi</th>
							<th>Mercredi</th>
							<th>Jeudi</th>
							<th>Vendredi</th>
						</tr>
					</thead>
					<tbody className='small'>
						{Array.isArray(dataGw) &&
							dataGw.map((item, i) => (
								<>
									<tr>
										<td className='text-warning'>{item.Guild}</td>
										<td>{item.Monday}</td>
										<td>{item.Tuesday}</td>
										<td>{item.Wednesday}</td>
										<td>{item.Thursday}</td>
										<td>{item.Friday}</td>
									</tr>
								</>
							))}

						{!Array.isArray(dataGw) && (
							<>
								<tr>
									<td colSpan='6'>En attente d'un Coach.</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
			</section>

			<PageFooter />
		</>
	);
};

export default GW;
