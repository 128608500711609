import React from "react";
import { Image } from "react-bootstrap";

import "./HwBtnBrown.css";

const HwBtnBrownLg = (props) => {
	return (
		<>
			<section className='m-1 HwBtnBrownLG'>
				<span className='HwBtnBrownLG-TXT align-middle justify-content-center'>
					{props.Content}
				</span>
			</section>
		</>
	);
};

export default HwBtnBrownLg;
