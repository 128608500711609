// Doc play list you tube
// https://www.freecodecamp.org/news/how-to-add-a-youtube-playlist-to-a-nextjs-react-app-with-the-youtube-api/

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import "./Partners.css";

// const YOUTUBE_PLAYLIST_ITEMS_API =
// 	"https://www.googleapis.com/youtube/v3/playlistItems?key=" +
// 	process.env.YOUTUBE_API_KEY;

const YOUTUBE_PLAYLIST_ITEMS_API =
	"https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=2&playlistId=PL6KQg6LcySMIRdDg83Jt0ycujSsr8cKh0&key=AIzaSyBlnG3d91NlmVuGygnenPqMktYTaFdLTug";

const YouTubeApiUrl = "https://www.googleapis.com/youtube/v3/playlistItems";
const YouTubeOptions = "?part=snippet&maxResults=3";
const YouTubePlayListID = "PL6KQg6LcySMIRdDg83Jt0ycujSsr8cKh0";
const YouTubeApiKey = "AIzaSyBlnG3d91NlmVuGygnenPqMktYTaFdLTug";

const ITEMS = [
	{
		title: "Loading",
	},
];

const PartnersLeft = (props) => {
	const [youTubeItems, setYouTubeItems] = useState(ITEMS);

	useEffect(() => {
		const getData = async () => {
			const response = await axios.get(
				`${YouTubeApiUrl}${YouTubeOptions}&playlistId=${YouTubePlayListID}&key=${YouTubeApiKey}`,
			);

			console.log("data", response);
			console.log("data", response.data.items);

			setYouTubeItems(response.data.items);
		};
		getData();
	}, []);

	// const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}`);
	// const data = await res.json();

	return (
		<>
			<section className=''>
				{youTubeItems.map(({ id, snippet = {} }) => {
					const { title, thumbnails = {}, resourceId = {} } = snippet;
					const { medium } = thumbnails;
					return (
						<>
							<span
								className='PartnerCard mt-3'
								style={{ justifyContent: "center", width: "250px" }}
							>
								<span className=''>{title}</span>
								<a
									href={`https://www.youtube.com/watch?v=${resourceId?.videoId}`}
									target='_blank'
									rel='noreferrer'
								>
									<p className='pt-2'>
										<img width={"85%"} src={medium?.url} alt='' />
										<span className='float-end PartnerCardBy text-white'>
											By Wakiwuuuu
										</span>
									</p>
								</a>
							</span>
						</>
					);
				})}
			</section>
		</>
	);
};

export default PartnersLeft;
