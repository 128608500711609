import React from "react";

import { Route, Switch } from "react-router-dom";

import ContentPolicy from "./Pages/ContentPolicy";
import HomePage from "./Pages/Home/Home";
import AdminGw from "./Pages/Admin/AdminGw";

//import Gw from "./Pages/Gw";
import GwSilver from "./Pages/GwSilver";
import GwBronze from "./Pages/GwBronze";
import Adventures from "./Pages/Adventures";
import GuildRules from "./Pages/GuildRules/GuildRules";

import GwAdmin from "./Pages/GwAdmin";

import UserInstance from "./Stores/UserStore";

export const UserContext = React.createContext();

const Main = () => {
	return (
		<section>
			<UserContext.Provider value={UserInstance}>
				<Switch>
					<Route exact path='/' component={ContentPolicy} />
					<Route exact path='/ContentPolicy' component={ContentPolicy} />

					<Route exact path='/Home' component={HomePage} />

					<Route exact path='/Adventures' component={Adventures} />
					<Route exact path='/Adventures/:id' component={Adventures} />

					<Route exact path='/GuildRules' component={GuildRules} />

					<Route exact path='/AdminGW' component={AdminGw} />

					<Route exact path='/GwAdmin' component={GwAdmin} />

					<Route exact path='/Gw/Silver' component={GwSilver} />
					<Route exact path='/Gw/Silver/:server' component={GwSilver} />

					<Route exact path='/Gw/Bronze' component={GwBronze} />
					<Route exact path='/Gw/Bronze/:server' component={GwBronze} />
				</Switch>
			</UserContext.Provider>
		</section>
	);
};

export default Main;
