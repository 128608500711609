import React from "react";
import { Image } from "react-bootstrap";

import "./HwBtnAside.css";

const HwBtnAside = (props) => {

	return (
		<>
			<section className="m-1 HwBtnAside">
				
				<span className="HwBtnAside-TXT align-middle justify-content-center">
				{props.Content}
				</span>

				<span className='HwBtnAside-ICO'>
				<Image  src={"./../../PublicImg/Component/Btn/" + props.srcImg} />
				</span>

			</section>
		</>
	);
};

export default HwBtnAside;