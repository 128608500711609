import React from "react";
import { Image } from "react-bootstrap";

import LabelXs from "./HwLabelXs";

import "./HwBtnGuild.css";

const HwBtnGuild = (props) => {
	return (
		<>
			<section name='BtnGuild' className='HwBtnGuild text-center'>
				{/* <Image className='HwBtnCircle-ICO'  src="./../../PublicImg/Avatars/titan_160_4003.png" /> */}
				<Image className='HwBtnGuild-Frame' src={"./../../PublicImg/Frame/" + props.FrameLabel} />
				<span className='text-center align-self-center'>
					{/* <Image className='HwBtnCircle-ICO'  src="./../../PublicImg/Sanctuary_Icon.png" /> */}
					<Image className='HwBtnGuild-ICO' src={"./../../PublicImg" + props.ImgLabel} />

					<LabelXs TxtLabel={props.TxtLabel} />
				</span>
			</section>
		</>
	);
};

export default HwBtnGuild;
