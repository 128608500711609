import React from "react";
import { useHistory } from "react-router-dom";

import "./Btn.css";

const BtnGreen = (props) => {
	const history = useHistory();

	return (
		<>
			<div className='BtnGreen' onClick={() => history.push(props.pushPage)}>
				{props.Content}
			</div>
		</>
	);
};

export default BtnGreen;
